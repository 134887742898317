import React, { ReactElement, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCookies } from 'react-cookie';

import footerNavigationLinks, {
  FooterLink,
  FooterLinkBlock,
  FooterLinkType,
  footerDocsLinks,
} from '../../data/footer-navigation';

import Link from '../Link/Link';
import Logo from '../../assets/htb_logo_white.svg';
import FRLogo from '../../assets/fr-htb_logo_white.svg';
import { useLocaleContext } from '../../context/Locale';
import { LocaleTag } from '../../../config/locales';
import ExternalLink from '../ExternalLink/ExternalLink';
import { cookieOptions } from '../../shared/constants';
import './style.scss';

interface OwnProps {
  className?: string;
  inert?: boolean;
}

type Props = OwnProps;

const Footer: React.FC<Props> = ({ className = '', inert }) => {
  const { t } = useTranslation();
  const { activeLocaleTag } = useLocaleContext();
  const [, setCookies] = useCookies(['htb_consent']);

  const onClicks: { [key: string]: (e: any) => void } = {
    launchCookieConsent: (e) => {
      e.preventDefault();
      setCookies('htb_consent', false, cookieOptions);
    },
  };

  const getFooterLinks = (links: FooterLinkBlock[]) => {
    return links.map((link, i) => {
      return (
        <div className="column column-20" key={`footer-link-block${i}`}>
          <h2 className="h5">{t(link.headingKey)}</h2>
          <ul>
            {link.links.map((listLink) => {
              const props: {
                to: string;
                className: string;
                onClick?: (e: any) => void;
              } = {
                to: listLink.to,
                className: 'small',
              };

              if (listLink.to.includes('onClick')) {
                props.to = '/';
                props.onClick = onClicks[listLink.to.split('-')[1]];
              }

              return (
                <li key={listLink.to}>
                  <Link {...props}>{t(listLink.textKey)}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  };

  const getSecondaryFooterLinks = (links: FooterLink[]) => {
    return links.map((link) => {
      const linkProps: {
        to: string;
        href: string;
        className: string;
        children: ReactElement;
        onClick?: (e: any) => void;
      } = {
        to: link.to,
        href: link.to,
        className: 'small',
        children: t(link.textKey),
      };

      if (link.to.includes('onClick')) {
        linkProps.to = '/';
        linkProps.onClick = onClicks[link.to.split('-')[1]];
      }

      return (
        <li key={link.to}>
          {link.type === FooterLinkType.External ? (
            <ExternalLink {...linkProps} />
          ) : (
            <Link {...linkProps} />
          )}
        </li>
      );
    });
  };

  return (
    <footer className={`Footer ${className}`} {...(inert ? { inert: 'true' } : {})}>
      <div className="grid-container">
        <div className="row row-wrap Footer__links-wrapper">
          <div className="column column-20">
            {activeLocaleTag === LocaleTag.EN ? (
              <Logo className="Footer__logo-link" />
            ) : (
              <FRLogo className="Footer__logo-link" />
            )}
          </div>
          {getFooterLinks(footerNavigationLinks)}
        </div>

        <div className="row row-wrap Footer__bottom-links-wrapper">
          <ul>{getSecondaryFooterLinks(footerDocsLinks)}</ul>
        </div>

        <div className="row row-wrap Footer__bottom-links-wrapper">
          <p className="small">
            <Trans t={t} i18nKey="footerCopyright" values={{ year: new Date().getFullYear() }} />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
