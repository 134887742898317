import React from 'react';
// @ts-ignore
import ReactSelect, { Props, defaultTheme } from 'react-select';

import './style.scss';

const Select = (props: Props) => {
  const theme = {
    ...defaultTheme,
    borderRadius: 2,
    colors: {
      ...defaultTheme.colors,
      primary: '#006f44',
      primary25: '#b2cebd',
      neutral20: '#006f44',
    },
  };

  return (
    <ReactSelect
      className={`Select ${props.className || ''}`}
      classNamePrefix="react-select-custom"
      theme={theme}
      {...props}
    />
  );
};

export default Select;
