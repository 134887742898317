import { LocaleTag } from './locales';
import RouteKeys from './RouteKeys';

export interface NavigationRoute {
  path: {
    [LocaleTag.EN]: string;
    [LocaleTag.FR]: string;
  };
}

/**
 * These routes DO NOT contain language prefix such as '/en' or '/fr'.
 * They are added during build time. Check out gatsby-node.js for further information
 */

const localizedRoutes: NavigationRoute[] = [];

function getPathsRecursively(obj: any) {
  Object.entries(obj).forEach((item) => {
    const { INDEX, ...rest } = item[1] as any;
    if (INDEX) {
      // save it in the path
      localizedRoutes.push({ ...INDEX });
      if (Object.entries(rest).length > 0) {
        getPathsRecursively(rest);
      }
    }
  });
}

getPathsRecursively(RouteKeys);

export default localizedRoutes;
