import home from './home';
import aboutUs from './about-us';
import brokerCentre from './broker-centre';
import contactUs from './contact-us';
import careers from './careers';
import deposits from './deposits';
import customerCentre from './customer-centre';

export default [
  [...home],
  [...customerCentre],
  [...brokerCentre],
  [...aboutUs],
  [...contactUs],
  [...deposits],
  [...careers],
];
