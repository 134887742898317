import { LocaleTag } from '../../../config/locales';
import videos from '../video/videos';

const secondsPlusMortgageVideos = videos.find((v) => v.titleKey === 'secondsPlusMortgage.title');

// This page should only contain Websites and numbers. In rare cases it should have any other info
const CommonData = {
  mainWebsiteDomain: 'www.haventreebank.com',
  socialMediaLinks: {
    linkedIn: 'https://www.linkedin.com/company/haventree-bank/',
    facebook: 'https://www.facebook.com/haventreebank/',
    instagram: 'https://www.instagram.com/haventreebank/',
  },
  tollFreeCustomerServiceNumber: {
    depositServices: '1.855.272.0050',
    mortgageServicing: '1.855.272.0051',
    brokerRelated: '1.855.272.0053',
    complaintsDepartment: '1.855.272.0054',
    opcc: '1.800.282.1376',
    obsi: '1.888.451.4519',
    fcac: {
      [LocaleTag.EN]: '1.866.461.3222',
      [LocaleTag.FR]: '1.866.461.3222',
    },
    cdic: {
      [LocaleTag.EN]: '1.800.461.2342',
      [LocaleTag.FR]: '1.800.461.7232',
    },
    enCompass: '1.866.217.5993',
    gic: '1.855.272.0050',
  },
  telephoneNumber: {
    generic: {
      first: '416.361.0152',
      second: '416.631.0152',
    },
    toronto: {
      first: '647.277.0051',
      second: '416.361.0152',
    },
    privacyDept: '416.348.7922',
    obsi: {
      gta: '416.287.2877',
    },
    gic: '1.855.272.0050',
    gicTwo: '1.855.272.0050',
    solidify: '1.866.583.3983',
    valueConnect: '1.866.383.2473',
    rps: '1.877.658.8258',
    nas: '1.888.477.9776',
  },
  email: {
    solidify: 'support@solidifi.com',
    valueConnect: 'info@valueconnect.ca',
    rps: 'help@rpsrealsolutions.com',
    nas: 'clientsupport@nationwideappraisals.com',
  },
  faxNumber: {
    toronto: '416.342.0588',
    calgary: '416.342.0590',
    complaintsDepartment: '416.342.1095',
    opcc: '1.819.994.5424',
    obsi: {
      tollFree: '1.888.422.2865',
      gta: '416.225.4722',
    },
    gic: '437.253.6950',
  },
  ttyNumber: {
    opcc: '1.819.994.6591',
    obsi: '1-855-TTY-OBSI',
  },
  websites: {
    htb: {
      email: {
        accessibility: {
          [LocaleTag.EN]: 'accessibility@haventreebank.com',
          [LocaleTag.FR]: 'accessibilite@banquehaventree.com',
        },
      },
      phone: {
        accessibility: {
          [LocaleTag.EN]: '1.855.272.0051',
          [LocaleTag.FR]: '1.855.272.0051',
        },
      },
      linkedin: 'https://www.linkedin.com/company/haventree-bank/',
      renewMortgageCampaign: {
        [LocaleTag.EN]:
          'https://www.haventreebank.com/en/customer-centre/residential-mortgages/renew-mortgage/form/?utm_source=MC&utm_medium=Email&utm_campaign=Cashback23',
        [LocaleTag.FR]:
          'https://www.haventreebank.com/fr/espace-client/pr%C3%AAts-hypoth%C3%A9caires-r%C3%A9sidentiels/renouvellement-pr%C3%AAt-hypoth%C3%A9caire/formulaire/?utm_source=MC&utm_medium=Email&utm_campaign=Cashback23',
      },
      accessibility: {
        [LocaleTag.EN]: '/downloads/accessibility/AccessibilityReport(28May2024)-en.pdf',
        [LocaleTag.FR]: '/downloads/accessibility/AccessibilityReport(28May2024)-fr.pdf',
      },
    },
    brokerPortal: {
      [LocaleTag.EN]: 'https://brokerportal.haventreebank.com',
      [LocaleTag.FR]: 'https://portailcourtier.banquehaventree.com',
      avm: {
        [LocaleTag.EN]:
          'https://brokerportal.haventreebank.com/s/login/?language=en_US&ec=302&inst=Mm&startURL=%2Fbroker-centre%2Fbroker-portal%2Fapproved-appraiser-list%2Fauto-valuation-model',
        [LocaleTag.FR]:
          'https://portailcourtier.banquehaventree.com/s/login/?language=fr_CA&ec=302&inst=Mm&startURL=%2Fbroker-centre%2Fbroker-portal%2Fapproved-appraiser-list%2Fauto-valuation-model',
      },
    },
    canadaCa: {
      seniors: {
        [LocaleTag.EN]:
          'https://www.canada.ca/en/employment-social-development/campaigns/seniors.html',
        [LocaleTag.FR]: 'https://www.canada.ca/fr/emploi-developpement-social/campagne/aines.html',
      },
      financialAbuse: {
        [LocaleTag.EN]:
          'https://www.canada.ca/en/employment-social-development/corporate/seniors/forum/financial-abuse.html',
        [LocaleTag.FR]:
          'https://www.canada.ca/fr/emploi-developpement-social/ministere/aines/forum/exploitation-financiere.html',
      },
      theLittleBlackBookOfScams: {
        [LocaleTag.EN]:
          'https://ised-isde.canada.ca/site/competition-bureau-canada/en/little-black-book-scams-2nd-edition',
        [LocaleTag.FR]:
          'https://ised-isde.canada.ca/site/bureau-concurrence-canada/fr/petit-livre-noir-fraude-2e-edition',
      },
    },
    cba: {
      [LocaleTag.EN]: 'https://cba.ca/',
      [LocaleTag.FR]: 'https://cba.ca/?l=fr',
      volSeniors: {
        [LocaleTag.EN]:
          'https://cba.ca/Assets/CBA/Documents/Files/Article Category/PDF/vol-seniors-en.pdf',
        [LocaleTag.FR]:
          'https://cba.ca/Assets/CBA/Documents/Files/Article Category/PDF/vol-seniors-fr.pdf',
      },
    },
    cbaAttorney: {
      [LocaleTag.EN]: 'https://cba.ca/powers-of-attorney-what-consumers-need-to-know',
      [LocaleTag.FR]: 'https://cba.ca/powers-of-attorney-what-consumers-need-to-know?l=fr',
    },
    cbaJointAccounts: {
      [LocaleTag.EN]: 'https://cba.ca/joint-accounts-appropriate-use-of-joint-accounts',
      [LocaleTag.FR]: 'https://cba.ca/joint-accounts-appropriate-use-of-joint-accounts?l=fr',
    },
    cbaMortgages: {
      [LocaleTag.EN]: 'https://cba.ca/understanding-mortgages',
      [LocaleTag.FR]: 'https://cba.ca/understanding-mortgages?l=fr',
    },
    cdic: {
      [LocaleTag.EN]: 'https://www.cdic.ca/your-coverage/list-of-member-institutions/',
      [LocaleTag.FR]: 'https://www.sadc.ca/votre-protection/membres-de-la-sadc/',
      mainWebsite: {
        [LocaleTag.EN]: 'https://www.cdic.ca',
        [LocaleTag.FR]: 'https://www.sadc.ca',
      },
    },
    cdicBrochure: {
      [LocaleTag.EN]:
        'https://www.cdic.ca/wp-content/uploads/cdic-protecting-your-deposits-brochure-en.pdf',
      [LocaleTag.FR]:
        'https://www.cdic.ca/wp-content/uploads/cdic-protecting-your-deposits-brochure-fr.pdf',
    },
    dayForceHCM: {
      [LocaleTag.EN]:
        'https://recruiting.ultipro.ca/HAV5100HBANK/JobBoard/cac8df59-088b-4449-aa9d-7a419f8521f1',
      [LocaleTag.FR]:
        'https://recruiting.ultipro.ca/HAV5100HBANK/JobBoard/cac8df59-088b-4449-aa9d-7a419f8521f1',
    },
    eliteLoyaltySite: {
      [LocaleTag.EN]: 'https://elitebroker.rewardsnation.com/',
      [LocaleTag.FR]: 'https://elitebroker.rewardsnation.com/',
    },
    enCompassCertificateLink: {
      [LocaleTag.EN]: 'https://encompasshomeservice.com/HAVENTREE/certificate.php',
      [LocaleTag.FR]: 'http://serviceresidentielencompass.com/HAVENTREE/Certificat.php',
    },
    equifax: {
      creditReport: {
        [LocaleTag.EN]: 'https://www.consumer.equifax.ca/personal/products/credit-score-report/',
        [LocaleTag.FR]:
          'https://www.consumer.equifax.ca/personnel/produits/score-et-dossier-de-credit/',
      },
      dispute: {
        [LocaleTag.EN]: 'https://www.consumer.equifax.ca/personal/dispute-credit-report/',
        [LocaleTag.FR]: 'https://www.consumer.equifax.ca/personnel/contester-dossier-de-credit/',
      },
    },
    fcac: {
      [LocaleTag.EN]: 'https://www.canada.ca/en/financial-consumer-agency.html',
      [LocaleTag.FR]: 'https://www.canada.ca/fr/agence-consommation-matiere-financiere.html',
    },
    fcacMortgages: {
      [LocaleTag.EN]: 'https://www.canada.ca/en/financial-consumer-agency/services/mortgages.html',
      [LocaleTag.FR]:
        'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/hypotheques.html',
    },
    findBroker: {
      [LocaleTag.EN]: 'https://mortgageproscan.ca/findabroker',
      [LocaleTag.FR]: 'https://mortgageproscan.ca/fr/trouvezvotrecourtier',
    },
    justice: {
      rightsNFreedom: {
        [LocaleTag.EN]: 'https://www.justice.gc.ca/eng/csj-sjc/rfc-dlc/ccrf-ccdl/',
        [LocaleTag.FR]: 'https://www.justice.gc.ca/fra/sjc-csj/dlc-rfc/ccdl-ccrf/',
      },
    },
    obsi: {
      [LocaleTag.EN]: 'https://www.obsi.ca/en/',
      [LocaleTag.FR]: 'https://www.obsi.ca/fr/',
    },
    opcc: {
      [LocaleTag.EN]: 'https://www.priv.gc.ca/en/',
      [LocaleTag.FR]: 'https://www.priv.gc.ca/fr/',
      contact: {
        [LocaleTag.EN]: 'https://www.priv.gc.ca/en/contact-the-opc/',
        [LocaleTag.FR]: 'https://www.priv.gc.ca/fr/communiquer-avec-le-commissariat/',
      },
    },
    payout: {
      [LocaleTag.EN]: 'https://payout.firstcdn.com/Profile/Login.aspx',
      [LocaleTag.FR]:
        'https://payout.firstcdn.com/Profile/Login.aspx?OriginalUrlReferrer=/Profile/Login.aspx&Language=French',
    },
    transUnion: {
      [LocaleTag.EN]: 'https://www.transunion.ca/',
      [LocaleTag.FR]: 'https://transunion.ca/fr',
      dispute: {
        [LocaleTag.EN]: 'https://www.transunion.ca/assistance/credit-report-disputes',
        [LocaleTag.FR]: 'https://www.transunion.ca/fr/assistance/contestation-de-credit',
      },
    },
  },
  videos: {
    deferringMortgagePayments: {
      [LocaleTag.EN]: 'https://vimeo.com/415303828',
      [LocaleTag.FR]: 'https://vimeo.com/441386606',
    },
    eliteLoyaltyVideoLink: {
      [LocaleTag.EN]: 'https://vimeo.com/457078325',
      [LocaleTag.FR]: 'https://vimeo.com/458564522',
    },
    secondsPlusMortgage: {
      [LocaleTag.EN]: secondsPlusMortgageVideos?.videoUrl.en,
      [LocaleTag.FR]: secondsPlusMortgageVideos?.videoUrl.fr,
    },
    yearReview2022: {
      [LocaleTag.EN]: 'https://vimeo.com/794195635',
      [LocaleTag.FR]: 'https://vimeo.com/829432296',
    },
  },
  apis: {
    appraisals: '/appraisals',
  },
};

export default CommonData;
