import React, { memo } from 'react';
import MenuNavigationLink, { NestedLink } from '../MenuNavigationLink/MenuNavigationLink';
import './style.scss';

const NavigationBar: React.FC<Props> = ({ navigationLinks, className = '' }) => {
  const generateLinks = () => {
    return navigationLinks.map((innerLinks: NestedLink[], i) => {
      return innerLinks.map((innerLink) => (
        <MenuNavigationLink {...innerLink} key={i} className="NavigationBar__first-link" />
      ));
    });
  };

  const links = generateLinks();

  return (
    <nav className={`NavigationBar ${className}`}>
      {links.length ? <ul className="NavigationBar__links">{links}</ul> : null}
    </nav>
  );
};

interface OwnProps {
  navigationLinks: NestedLink[][];
  className?: string;
}

type Props = OwnProps;

export default memo(NavigationBar);
