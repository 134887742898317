// global gtag
import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation, Trans } from 'react-i18next';

import Input, { InputType } from '../primitives/Input/Input';
import Button, { ButtonType } from '../primitives/Button/Button';
import { cookieOptions } from '../../shared/constants';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import ExternalLink from '../ExternalLink/ExternalLink';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

interface OwnProps {
  className?: string;
  setInert: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = OwnProps;

const CookieConsent: React.FC<Props> = ({ setInert }) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['htb_consent', 'htb_functional', 'htb_analytics']);
  const [functional, setFunctional] = useState<boolean>(cookies.htb_functional || false);
  const [analytics, setAnalytics] = useState<boolean>(cookies.htb_analytics || false);
  const consent = cookies.htb_consent;
  const ref = useRef<HTMLInputElement>(null);

  const handleAnalyticsConsent = ({
    functionalStorage,
    analyticsStorage,
  }: {
    functionalStorage: boolean;
    analyticsStorage: boolean;
  }) => {
    const googleTag = (window as any)?.gtag;
    if (typeof googleTag === 'function') {
      googleTag('consent', 'update', {
        analytics_storage: analyticsStorage ? 'granted' : 'denied',
        functionality_storage: functionalStorage ? 'granted' : 'denied',
      });
    }
  };

  useEffect(() => {
    if (!cookies.htb_consent) {
      setCookie('htb_consent', false, cookieOptions);
    } else {
      handleAnalyticsConsent({
        functionalStorage: cookies.htb_functional || false,
        analyticsStorage: cookies.htb_analytics || false,
      });
    }
  }, []);

  useEffect(() => {
    if (consent === false) {
      if (ref.current) {
        ref.current.focus();
      }

      setInert(true);
    } else {
      setInert(false);
    }
  }, [consent]);

  const handleCheckboxChange = (val: any, id: string) => {
    switch (id) {
      case 'functional':
        setFunctional(!functional);
        break;
      case 'analytics':
        setAnalytics(!analytics);
        break;
      default:
    }
  };

  const acceptSelected = () => {
    setCookie('htb_functional', functional, cookieOptions);
    setCookie('htb_analytics', analytics, cookieOptions);

    handleAnalyticsConsent({
      functionalStorage: functional,
      analyticsStorage: analytics,
    });

    setCookie('htb_consent', true, cookieOptions);
  };

  const acceptAll = () => {
    setCookie('htb_functional', true, cookieOptions);
    setCookie('htb_analytics', true, cookieOptions);
    setCookie('htb_consent', true, cookieOptions);

    handleAnalyticsConsent({
      functionalStorage: true,
      analyticsStorage: true,
    });
  };

  const handlePrivacyLinkClick = () => {
    acceptSelected();
  };

  return cookies.htb_consent === false ? (
    <div id="cookie-consent" role="dialog" tabIndex={-1} ref={ref}>
      <div className="cookie-consent-container Home__max-width-container">
        <div className="generic-gap">
          <h2 className="h3">{t('cookieConsent.heading')}</h2>
          <p>{t('cookieConsent.description')}</p>
        </div>

        <div className="generic-gap">
          <Input
            id="essential"
            options={{
              type: InputType.CHECKBOX,
              label: <Trans t={t} i18nKey="cookieConsent.essential" />,
              inputProps: {
                name: 'essential',
                value: 'true',
                disabled: true,
                checked: true,
                ref,
              },
            }}
          />

          <Input
            id="functional"
            options={{
              type: InputType.CHECKBOX,
              label: <Trans t={t} i18nKey="cookieConsent.functional" />,
              inputProps: {
                name: 'functional',
                value: functional.toString(),
                checked: functional,
              },
            }}
            onChange={handleCheckboxChange}
          />

          <Input
            id="analytics"
            options={{
              type: InputType.CHECKBOX,
              label: <Trans t={t} i18nKey="cookieConsent.analytics" />,
              inputProps: {
                name: 'analytics',
                value: analytics.toString(),
                checked: analytics,
              },
            }}
            onChange={handleCheckboxChange}
          />
        </div>

        <p>
          <Trans t={t} i18nKey="cookieConsent.footerNote">
            <ExternalLink
              href={`${getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)}#${t(
                `${LocaleNameSpaceKeys.PRIVACY_STATEMENT}:quickLinks.informationAboutWebsite.id`
              )}`}
              onClick={handlePrivacyLinkClick}
              shouldOpenInNewTab={false}
            />
          </Trans>
        </p>
        <p className="small">{t('cookieConsent.privacyNote')}</p>

        <div className="button-container">
          <Button
            id="consent-accept-selected"
            styleOptions={{ isInline: true, type: ButtonType.TERTIARY }}
            onClick={acceptSelected}
          >
            {t('cookieConsent.buttonLabels.acceptSelected')}
          </Button>
          <Button
            id="consent-accept-all"
            styleOptions={{ isInline: true, type: ButtonType.TERTIARY }}
            onClick={acceptAll}
          >
            {t('cookieConsent.buttonLabels.acceptAll')}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieConsent;
