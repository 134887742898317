import React, { memo, useState, useRef } from 'react';
import './style.scss';
import ClassName from 'classnames';
import { useTranslation } from 'react-i18next';
import { NestedLink } from '../MenuNavigationLink/MenuNavigationLink';
import MobileNavigationLink from '../MobileNavigationLink/MobileNavigationLink';
import Button, { ButtonType } from '../primitives/Button/Button';
import { IconTypes } from '../Icon/Icon';

type NestedGroup = NestedLink[][];

const MobileMenu: React.FC<Props> = ({
  navigationLinks,
  toggle,
  isOpen,
  children,
  className = '',
}) => {
  const [currentMenu, setCurrentMenu] = useState<NestedLink[][]>(navigationLinks);
  const { t } = useTranslation();

  const previousMenuRef = useRef<NestedGroup[]>([]);
  const previousIndex = useRef(-1);

  const handleNavigationLinkClick = (linkBlock: NestedLink) => {
    const nestedLinks = linkBlock.nestedLinks ?? [];
    previousMenuRef.current.push(currentMenu);
    previousIndex.current += 1;
    setCurrentMenu([[{ ...linkBlock, nestedLinks: undefined }], nestedLinks]);
  };

  const onToggle = (value: boolean) => {
    previousMenuRef.current = [];
    previousIndex.current = -1;
    toggle(value);
    setCurrentMenu(navigationLinks);
  };

  const generateLinks = () => {
    return currentMenu.map((innerLinks: NestedLink[], i) => {
      return innerLinks.map((innerLink) => (
        <MobileNavigationLink
          {...innerLink}
          key={`${innerLink.textKey}-${i}`}
          className="MobileMenu__first-link"
          onClick={handleNavigationLinkClick}
          toggleMenuOnTap={onToggle}
        />
      ));
    });
  };

  const classnames = ClassName(`MobileMenu`, className, {
    MobileMenu__isOpen: isOpen,
  });

  const buttonClassNames = ClassName('MobileMenu__back-button', {
    'MobileMenu__button-is-visible': !!(
      previousMenuRef.current &&
      previousMenuRef.current[0] &&
      previousMenuRef.current[0][0] &&
      previousMenuRef.current[0][0].length > 0
    ),
  });

  const menuBack = () => {
    if (previousIndex.current < 0) return;
    setCurrentMenu(previousMenuRef.current[previousIndex.current]);
    previousMenuRef.current.pop();

    if (previousMenuRef.current[previousIndex.current] === navigationLinks) {
      previousMenuRef.current = [];
      previousIndex.current = -1;
    }

    previousIndex.current -= 1;
  };

  return (
    <div className={classnames}>
      <div className="MobileMenu__container">
        {children}
        <Button
          id="clear"
          type="button"
          onClick={menuBack}
          className={buttonClassNames}
          styleOptions={{ type: ButtonType.SECONDARY, isInline: true }}
          iconOptions={{
            icon: IconTypes.ARROW_LEFT,
          }}
        >
          {t('back')}
        </Button>
        <ul className="MobileMenu__links">{generateLinks()}</ul>
      </div>
    </div>
  );
};

interface OwnProps {
  navigationLinks: NestedLink[][];
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  toggle: (value: boolean) => any;
}

type Props = OwnProps;

export default memo(MobileMenu);
