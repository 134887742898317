import React, { memo, HTMLAttributes } from 'react';
import classnames from 'classnames';
import './style.scss';
import Icon, { IconTypes } from '../../Icon/Icon';

export enum ButtonType {
  PRIMARY = 'Button__primary',
  SECONDARY = 'Button__secondary',
  TERTIARY = 'Button__tertiary',
}

export enum ButtonSize {
  SMALL = 'Button__small',
  LARGE = 'Button__large',
}

export interface ButtonStyleOptions {
  isInline?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
}

export interface ButtonIconOptions {
  icon: IconTypes;
  position?: 'left' | 'right'; // default is always left
}

export interface OwnProps {
  id: string;
  iconOptions?: ButtonIconOptions;
  children?: React.ReactNode;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  styleOptions?: ButtonStyleOptions;
  onClick?: (...args: any[]) => any;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
  disabled?: boolean;
}

type Props = OwnProps;

const defaultStyleOptions: ButtonStyleOptions = {
  isInline: false,
  type: ButtonType.PRIMARY,
  size: ButtonSize.LARGE,
};

const Button: React.FC<Props> = ({
  type = 'button',
  id,
  iconOptions,
  styleOptions,
  className = '',
  onClick = () => {},
  buttonProps,
  children = null,
  disabled,
}: Props) => {
  const styleOptionsWithDefault: ButtonStyleOptions = {
    ...defaultStyleOptions,
    ...styleOptions,
  };

  const classNames = classnames(
    `Button`,
    styleOptionsWithDefault.type,
    styleOptionsWithDefault.size,
    className,
    {
      'Button__inline-link': !!styleOptions?.isInline,
      Button__disabled: !!disabled,
      small: styleOptionsWithDefault.size === ButtonSize.SMALL,
    }
  );

  const getIcon = () => {
    if (iconOptions) {
      const IconComp = ({ className_ }: any) => (
        <span className={className_}>
          <Icon type={iconOptions.icon} />
        </span>
      );
      return (
        <IconComp className_={`Button__icon Button__icon-${iconOptions?.position || 'left'}`} />
      );
    }
    return React.Fragment;
  };

  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <button
      type={type}
      className={classNames}
      disabled={disabled}
      onClick={handleOnClick}
      {...buttonProps}
    >
      {(!iconOptions?.position || iconOptions?.position === 'left') && getIcon()}
      {children}
      {iconOptions?.position === 'right' && getIcon()}
    </button>
  );
};

export default memo(Button);
