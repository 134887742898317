import routes, { NavigationRoute } from '../../config/localizedRoutes';

/**
 * Key - Path in English
 * Value - The whole object
 */
const mappedRoutes = new Map<string, NavigationRoute>();

routes.forEach((route: NavigationRoute) => {
  mappedRoutes.set(route.path.en, route);
});

export default mappedRoutes;
