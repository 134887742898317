import { NestedLink } from '../../components/MenuNavigationLink/MenuNavigationLink';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const links: NestedLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.DEPOSITS),
    textKey: 'pageTitles.deposits',
    isPartiallyActive: true,
  },
];

export default links;
