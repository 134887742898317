import React from 'react';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';

import locales, { LocaleTag } from '../../../config/locales';
import { useLocaleContext } from '../../context/Locale';
import { Select } from '../primitives';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

interface OptionType {
  label: string;
  value: string;
}

const LanguagePicker = () => {
  const { activeLocaleTag, updateLocale } = useLocaleContext();
  const { t } = useTranslation();

  const handleChange = (args: ValueType<OptionType>) => {
    if (args) {
      updateLocale((args as OptionType).value as LocaleTag);
    }
  };

  const options: OptionType[] = locales.map((locale) => ({
    label: locale.label,
    value: locale.short.toString(),
  }));

  return (
    <div className="LanguagePicker">
      <Select
        options={options}
        value={options.find((locale) => locale.value === activeLocaleTag)}
        onChange={handleChange}
        isSearchable={false}
        aria-label={t(`${LocaleNameSpaceKeys.COMMON}:ariaLabels.languagePicker`)}
      />
    </div>
  );
};

export default LanguagePicker;
