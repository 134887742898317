import React, { memo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Link from '../Link/Link';
import ExternalLink from '../ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import { useLocaleContext } from '../../context/Locale';
import './style.scss';

export interface NestedLink {
  to: string;
  textKey: string;
  isPartiallyActive?: boolean;
  nestedLinks?: NestedLink[];
  className?: string;
  external?: boolean;
}

type Props = NestedLink;

const MenuNavigationLink: React.FC<Props> = ({
  to,
  textKey,
  nestedLinks,
  className = '',
  isPartiallyActive,
  external = false,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement>(null);

  const handleClose = (event: any) => {
    if (
      event.relatedTarget instanceof Node &&
      !event.currentTarget?.contains(event.relatedTarget)
    ) {
      if (open) {
        setOpen(false);
      }
    }
  };

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const generateNestedLinksRecursive = (links: NestedLink[] | undefined): any => {
    if (links && links.length > 0) {
      return links.map((link, i) => {
        return <MenuNavigationLink {...link} key={`${i}`} />;
      });
    }

    return null;
  };

  const dropDownOpenClassName = classNames('MenuNavigationLink__Dropdown', {
    'MenuNavigationLink__Dropdown--open': open,
  });

  const getIsPartiallyActive = () => {
    if (to !== '/') {
      return typeof isPartiallyActive !== 'undefined'
        ? isPartiallyActive
        : nestedLinks && nestedLinks.length > 1;
    }

    return false;
  };

  useEffect(() => {
    if (nestedLinks?.length) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (open) {
          switch (event.key) {
            case 'Escape': {
              const parent = (event.target as HTMLElement)?.closest('.has-nested-links');

              if (!parent || parent === ref.current) {
                setOpen(false);
              }

              break;
            }

            default:
          }
        }
      };

      window.addEventListener('keydown', handleKeyPress);
      return () => window.removeEventListener('keydown', handleKeyPress);
    }

    return undefined;
  }, [open]);

  const { activeLocaleTag } = useLocaleContext();
  const recrusiveLinks = generateNestedLinksRecursive(nestedLinks);

  return (
    <li
      className={[
        'MenuNavigationLink',
        className,
        nestedLinks?.length && open ? 'has-nested-links' : '',
      ].join(' ')}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onFocus={handleOpen}
      onBlur={handleClose}
      tabIndex={-1}
      ref={ref}
    >
      {external ? (
        <ExternalLink
          href={CommonData.websites.brokerPortal[activeLocaleTag]}
          className={`MenuNavigationLink__internal-link ${className}`}
          aria-haspopup={recrusiveLinks !== null}
          aria-expanded={recrusiveLinks !== null && open}
          tabIndex={0}
        >
          {t(textKey)}
        </ExternalLink>
      ) : (
        <Link
          to={to}
          className={`MenuNavigationLink__internal-link ${className}`}
          activeClassName={`MenuNavigationLink__internal-link--active ${className}`}
          partiallyActive={getIsPartiallyActive()}
          aria-haspopup={recrusiveLinks !== null}
          aria-expanded={recrusiveLinks !== null && open}
          tabIndex={0}
        >
          {t(textKey)}
        </Link>
      )}
      {recrusiveLinks ? (
        <ul className={dropDownOpenClassName} aria-hidden={!open}>
          {recrusiveLinks}
        </ul>
      ) : null}
    </li>
  );
};

export default memo(MenuNavigationLink);
