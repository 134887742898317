import { LocaleTag } from './locales';

/**
 * There must always be an index key. It represent the path of page
 *  Nested paths have to be defined manually and will need to be maintained
 */
const RouteKeys = {
  NOT_FOUND: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/404/',
        [LocaleTag.FR]: '/404/',
      },
    },
  },
  NOT_FOUND_HTML: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/404.html',
        [LocaleTag.FR]: '/404.html',
      },
    },
  },
  DEV_NOT_FOUND: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/dev-404-page/',
        [LocaleTag.FR]: '/dev-404-page/',
      },
    },
  },
  ELECTRONIC_ACCESS_AGREEMENT: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/electronic-access-agreement/',
        [LocaleTag.FR]: '/entente-accès-électronique/',
      },
    },
  },
  PRIVACY_STATEMENT: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/privacy-statement/',
        [LocaleTag.FR]: '/déclaration-confidentialité/',
      },
    },
  },
  TERMS_CONDITION: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/terms-conditions/',
        [LocaleTag.FR]: '/modalités/',
      },
    },
  },
  REGULATORY_INFORMATION: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/regulatory-information/',
        [LocaleTag.FR]: '/renseignements-réglementaires/',
      },
    },
  },
  ACCESSIBILITY: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/accessibility/',
        [LocaleTag.FR]: '/accessibilité/',
      },
    },
  },
  BORROWER: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/borrower/',
        [LocaleTag.FR]: '/emprunteur/',
      },
    },
  },
  PROMOTIONS: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/promotions/',
        [LocaleTag.FR]: '/promotions/',
      },
    },
  },
  HOME: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/',
        [LocaleTag.FR]: '/',
      },
    },
  },
  CUSTOMER_CENTRE: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/customer-centre/',
        [LocaleTag.FR]: '/espace-client/',
      },
    },
    UNDERSTANDING_YOUR_MORTGAGE: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/understanding-your-mortgage/',
          [LocaleTag.FR]: '/espace-client/comprendre-votre-prêt-hypothécaire/',
        },
      },
    },
    RESIDENTIAL_MORTGAGES: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/residential-mortgages/',
          [LocaleTag.FR]: '/espace-client/prêts-hypothécaires-résidentiels/',
        },
      },
      RENEW_YOUR_MORTGAGE: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/residential-mortgages/renew-mortgage/',
            [LocaleTag.FR]:
              '/espace-client/prêts-hypothécaires-résidentiels/renouvellement-prêt-hypothécaire/',
          },
        },
        FORM: {
          INDEX: {
            path: {
              [LocaleTag.EN]: '/customer-centre/residential-mortgages/renew-mortgage/form/',
              [LocaleTag.FR]:
                '/espace-client/prêts-hypothécaires-résidentiels/renouvellement-prêt-hypothécaire/formulaire/',
            },
          },
        },
      },
      REFINANCING_MORTGAGE: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/residential-mortgages/refinancing-mortgage/',
            [LocaleTag.FR]:
              '/espace-client/prêts-hypothécaires-résidentiels/refinancement-prêt-hypothécaire/',
          },
        },
        FORM: {
          INDEX: {
            path: {
              [LocaleTag.EN]: '/customer-centre/residential-mortgages/refinancing-mortgage/form/',
              [LocaleTag.FR]:
                '/espace-client/prêts-hypothécaires-résidentiels/refinancement-prêt-hypothécaire/formulaire/',
            },
          },
        },
      },
      PURCHASING_HOME: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/residential-mortgages/purchasing-home/',
            [LocaleTag.FR]: '/espace-client/prêts-hypothécaires-résidentiels/achat-maison/',
          },
        },
      },
      IMPROVEMENTS: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/residential-mortgages/improvements/',
            [LocaleTag.FR]: '/espace-client/prêts-hypothécaires-résidentiels/rénovations/',
          },
        },
      },
    },
    // COVID19_FAQ: {
    //   INDEX: {
    //     path: {
    //       [LocaleTag.EN]: '/customer-centre/covid-19-faq/',
    //       [LocaleTag.FR]: '/espace-client/faq-covid-19/',
    //     },
    //   },
    // },
    HOME_WARRANTY: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/home-warranty/',
          [LocaleTag.FR]: '/espace-client/garantie-habitation/',
        },
      },
    },
    NEXTJOB_PROGRAM: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/nextjob-program/',
          [LocaleTag.FR]: '/espace-client/programme-prochain-emploi/',
        },
      },
    },
    MORTGAGE_SERVICE_DOCUMENTS: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/mortgage-service-documents/',
          [LocaleTag.FR]: '/espace-client/documents-administration-prêt-hypothécaire/',
        },
      },
    },
    PREPAYMENT_CALCULATOR: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/prepayment-calculator/',
          [LocaleTag.FR]: '/espace-client/calculateur-paiement-anticipé/',
        },
      },
    },
    MAKE_A_CLAIM: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/make-a-claim/',
          [LocaleTag.FR]: '/espace-client/demande-règlement/',
        },
      },
    },
    FAQ: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/faq/',
          [LocaleTag.FR]: '/espace-client/faq/',
        },
      },
    },
    RESOURCES: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/resources/',
          [LocaleTag.FR]: '/espace-client/ressources/',
        },
      },
      FINANCIAL_LITERACY: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/resources/financial-literacy/',
            [LocaleTag.FR]: '/espace-client/ressources/connaissance-financière/',
          },
        },
      },
      FINANCIAL_TOOLS_RESOURCES: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/customer-centre/resources/financial-tools-resources/',
            [LocaleTag.FR]: '/espace-client/ressources/outils-ressources-financières/',
          },
        },
        BUDGET_PLANNER: {
          INDEX: {
            path: {
              [LocaleTag.EN]:
                '/customer-centre/resources/financial-tools-resources/budget-planner/',
              [LocaleTag.FR]:
                '/espace-client/ressources/outils-ressources-financières/planificateur-budgétaire/',
            },
          },
        },
      },
    },
    PROGRAMS: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/customer-centre/programs/',
          [LocaleTag.FR]: '/espace-client/programmes/',
        },
      },
    },
  },
  BROKER_CENTRE: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/broker-centre/',
        [LocaleTag.FR]: '/espace-courtier/',
      },
    },
    VIDEO_LIBRARY: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/broker-centre/video-library/',
          [LocaleTag.FR]: '/espace-courtier/vidéothèque/',
        },
      },
      INSTANT_BANK_STATEMENT_VERIFICATION_TOOL: {
        INDEX: {
          path: {
            [LocaleTag.EN]:
              '/broker-centre/video-library/instant-bank-statement-verification-tool/',
            [LocaleTag.FR]:
              '/espace-courtier/vidéothèque/outil-vérification-instantanée-relevés-bancaires/',
          },
        },
      },
      DEBT_CONSOLIDATION: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/debt-consolidation/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/consolidation-dette/',
          },
        },
      },
      SECONDS_PLUS_MORTGAGE: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/seconds-plus-mortgage/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/hypothèque-second-rang-seconds-plus/',
          },
        },
      },
      EXPEDITE_YOUR_DEAL: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/expedite-your-deal/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/accélérer-votre-transaction/',
          },
        },
      },
      BFS_ACCELERATED_PROGRAM: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/bfs-accelerated-program/',
            [LocaleTag.FR]:
              '/espace-courtier/vidéothèque/programme-accéléré-travailleurs-autonomes/',
          },
        },
      },
      PASSWORD_RESET: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/password-reset/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/réinitialiser-votre-mot-de-passe/',
          },
        },
      },
      POINTS_REDEMPTION: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/points-redemption/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/échangez-vos-points/',
          },
        },
      },
      LOW_FICO: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/low-fico/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/fico-faible/',
          },
        },
      },
      REFINANCE: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/refinance-commision/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/refinancement-commissions/',
          },
        },
      },
      GETTING_STARTED_BROKER_PORTAL: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/getting-started-broker-portal/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/démarrer-portail-courtier/',
          },
        },
      },
      RESET_PASSWORD_BROKER_PORTAL: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/reset-password-broker-portal/',
            [LocaleTag.FR]:
              '/espace-courtier/vidéothèque/réinitialiser-mot-de-passe-portail-courtier/',
          },
        },
      },
      ACCESS_LOAN_BROKER_PORTAL: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/access-loan-broker-portal/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/accéder-prêts-portail-courtier/',
          },
        },
      },
      UPDATE_CONTACT_BROKER_PORTAL: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/update-contact-broker-portal/',
            [LocaleTag.FR]:
              '/espace-courtier/vidéothèque/mettre-à-jour-coordonnées-portail-courtier/',
          },
        },
      },
      LOAD_DOCUMENTS: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/video-library/load-documents/',
            [LocaleTag.FR]: '/espace-courtier/vidéothèque/charger-des-documents/',
          },
        },
      },
    },
    BROKER_PORTAL: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/broker-centre/broker-portal/',
          [LocaleTag.FR]: '/espace-courtier/portail-courtier/',
        },
      },
      LOGIN: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/broker-portal/login/',
            [LocaleTag.FR]: '/espace-courtier/portail-courtier/connexion/',
          },
        },
      },
      LENDING_AREAS: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/broker-portal/lending-areas/',
            [LocaleTag.FR]: '/espace-courtier/portail-courtier/domaines-prêts/',
          },
        },
      },
      APPROVED_APPRAISER_LIST: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/broker-portal/approved-appraiser-list/',
            [LocaleTag.FR]: '/espace-courtier/portail-courtier/liste-évaluateurs-approuvés/',
          },
        },
      },
      SLIDING_SCALE_CALCULATOR: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/broker-portal/maximum-mortgage-calculator/',
            [LocaleTag.FR]: '/espace-courtier/portail-courtier/calculateur-hypothécaire-maximal/',
          },
        },
      },
      FAQ: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/broker-centre/broker-portal/faq/',
            [LocaleTag.FR]: '/espace-courtier/portail-courtier/faq/',
          },
        },
      },
    },
    MEET_THE_TEAM: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/broker-centre/meet-the-team/',
          [LocaleTag.FR]: '/espace-courtier/rencontrez-l-equipe/',
        },
      },
    },
  },
  ABOUT_US: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/about-us/',
        [LocaleTag.FR]: '/à-propos-de-nous/',
      },
    },
    COMMUNITY_CAREGIVING: {
      INDEX: {
        path: {
          [LocaleTag.EN]: `/about-us/community-caregiving/`,
          [LocaleTag.FR]: '/à-propos-de-nous/soutien-collectivité/',
        },
      },
    },
    FRAUD_ALERT: {
      INDEX: {
        path: {
          [LocaleTag.EN]: `/about-us/fraud-alert/`,
          [LocaleTag.FR]: '/à-propos-de-nous/alerte-fraude/',
        },
      },
    },
    SCC: {
      INDEX: {
        path: {
          [LocaleTag.EN]: `/about-us/scc/`,
          [LocaleTag.FR]: '/à-propos-de-nous/scc/',
        },
      },
    },
  },
  CONTACT_US: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/contact-us/',
        [LocaleTag.FR]: '/nous-joindre/',
      },
    },
    CUSTOMER_SERVICE_SUPPORT: {
      INDEX: {
        path: {
          [LocaleTag.EN]: `/contact-us/customer-service-support/`,
          [LocaleTag.FR]: '/nous-joindre/soutien-service-clientèle/',
        },
      },
    },
    COMPLAINTS_HANDLING: {
      INDEX: {
        path: {
          [LocaleTag.EN]: `/contact-us/complaints-handling/`,
          [LocaleTag.FR]: '/nous-joindre/gestion-plaintes',
        },
      },
    },
  },
  DEPOSITS: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/deposits/',
        [LocaleTag.FR]: '/dépôts/',
      },
    },
    CURRENT_DEPOSIT_RATES: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/deposits/current-deposit-rates/',
          [LocaleTag.FR]: '/dépôts/taux-dépôts-courants/',
        },
      },
    },
    FAQ: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/deposits/faq/',
          [LocaleTag.FR]: '/dépôts/faq/',
        },
      },
    },
    PROMOTIONS: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/deposits/promotions/',
          [LocaleTag.FR]: '/dépôts/promotions/',
        },
      },
    },
  },
  CAREERS: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/careers/',
        [LocaleTag.FR]: '/carrières/',
      },
    },
    INTERNSHIP: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/careers/internship/',
          [LocaleTag.FR]: '/carrières/stage/',
        },
      },
      LEO_SANDLER: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/careers/internship/leo-sandler/',
            [LocaleTag.FR]: '/carrières/stage/leo-sandler/',
          },
        },
      },
      JOHN_MICHAEL_CLARK: {
        INDEX: {
          path: {
            [LocaleTag.EN]: '/careers/internship/john-michael-clark/',
            [LocaleTag.FR]: '/carrières/stage/john-michael-clark/',
          },
        },
      },
    },
  },
  LEGAL_DISCLAIMER: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/legal-disclaimer/',
        [LocaleTag.FR]: '/avis-de-non-responsabilité/',
      },
    },
  },
  INSTANT_VERIFICATION: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/instant-verification/',
        [LocaleTag.FR]: '/vérification-instantanée/',
      },
    },
    BANKING_INFORMATION: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/instant-verification/banking-information/',
          [LocaleTag.FR]: '/vérification-instantanée/information-bancaire/',
        },
      },
    },
    BANK_STATEMENT: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/instant-verification/bank-statement/',
          [LocaleTag.FR]: '/vérification-instantanée/relevé bancaire/',
        },
      },
    },
  },
  LOYALTY: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/loyalty/',
        [LocaleTag.FR]: '/fidélité/',
      },
    },
  },
  CCP: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/ccp/',
        [LocaleTag.FR]: '/ccp/',
      },
    },
    CASHBACK: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/ccp/cashback/',
          [LocaleTag.FR]: '/ccp/cashback/',
        },
      },
    },
    RENEW: {
      INDEX: {
        path: {
          [LocaleTag.EN]: '/ccp/renew/',
          [LocaleTag.FR]: '/ccp/renouvelez/',
        },
      },
    },
  },
  SOCIAL: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/social/',
        [LocaleTag.FR]: '/social/',
      },
    },
  },
  APPRAISAL_REBATE: {
    INDEX: {
      path: {
        [LocaleTag.EN]: '/appraisalrebate/',
        [LocaleTag.FR]: '/offreevaluations/',
      },
    },
  },
};

// Keys should always be top level
export default RouteKeys;
