import React from 'react';
import ArrowNext from '../../assets/svgs/arrow-next.svg';
import Team from '../../assets/svgs/team.svg';
import LocationPin from '../../assets/svgs/location-pin.svg';
import EmailAtSign from '../../assets/svgs/email-at-sign.svg';
import Phone from '../../assets/svgs/phone.svg';
import Smartphone from '../../assets/svgs/smartphone.svg';
import Group from '../../assets/svgs/group.svg';
import HouseInHand from '../../assets/svgs/house-in-hand.svg';
import BarChart from '../../assets/svgs/bar-chart.svg';
import AlertCircle from '../../assets/svgs/alert-circle.svg';
import Download from '../../assets/svgs/download.svg';
import Dollar from '../../assets/svgs/dollar.svg';
import SendMail from '../../assets/svgs/send-mail.svg';
import Checkmark from '../../assets/svgs/checkmark.svg';
import DocumentWarning from '../../assets/svgs/document-warning.svg';
import RescueRing from '../../assets/svgs/rescue-ring.svg';
import Mail from '../../assets/svgs/mail.svg';
import ArrowDown from '../../assets/svgs/arrow-down.svg';
import Lock from '../../assets/svgs/lock.svg';
import World from '../../assets/svgs/world.svg';
import ArrowLeft from '../../assets/svgs/arrow-left.svg';
import ArrowNextThin from '../../assets/svgs/arrow-next-thin.svg';
import Search from '../../assets/svgs/search.svg';
import BrokerPortal from '../../assets/svgs/broker-portal.svg';
import BrokerPromotions from '../../assets/svgs/broker-promotions.svg';
import ClientPortal from '../../assets/svgs/client-portal.svg';
import ClientResources from '../../assets/svgs/client-resources.svg';
import Covid19Support from '../../assets/svgs/covid19-support.svg';
import EliteLoyalty from '../../assets/svgs/elite-loyalty.svg';
import Encompass from '../../assets/svgs/encompass.svg';
import FinancialLiteracy from '../../assets/svgs/financial-literacy.svg';
import FinancialToolsResources from '../../assets/svgs/financial-tools-resources.svg';
import MortgageServicingDocs from '../../assets/svgs/mortgage-servicing-docs.svg';
import NextJob from '../../assets/svgs/nextjob.svg';
import PrepaymentCalculator from '../../assets/svgs/prepayment-calculator.svg';
import VideoLibrary from '../../assets/svgs/video-library.svg';
import MeetTheTeam from '../../assets/svgs/meet-the-team.svg';
import LendingAreas from '../../assets/svgs/lending-areas.svg';
import CDIC_ICON from '../../assets/svgs/cdic.svg';
import SADC_ICON from '../../assets/svgs/sadc.svg';
import Chart from '../../assets/svgs/chart.svg';
import Target from '../../assets/svgs/target.svg';
import LightBulb from '../../assets/svgs/light-bulb.svg';
import HeadGear from '../../assets/svgs/head-gear.svg';
import Certificate from '../../assets/svgs/certificate.svg';
import House from '../../assets/svgs/house.svg';
import Settings from '../../assets/svgs/settings.svg';
import Calendar from '../../assets/svgs/calendar.svg';
import Save from '../../assets/svgs/save.svg';

export enum IconTypes {
  ARROW_NEXT = 'ARROW_NEXT',
  TEAM = 'TEAM',
  LOCATION_PIN = 'LOCATION_PIN',
  EMAIL_AT_SIGN = 'EMAIL_AT_SIGN',
  PHONE = 'PHONE',
  SMART_PHONE = 'SMART_PHONE',
  GROUP = 'GROUP',
  HOUSE_IN_HAND = 'HOUSE_IN_HAND',
  BAR_CHART = 'BAR_CHART',
  ALERT_CIRCLE = 'ALERT_CIRCLE',
  DOWNLOAD = 'DOWNLOAD',
  DOLLAR = 'DOLLAR',
  SEND_MAIL = 'SEND_MAIL',
  CHECK_MARK = 'CHECK_MARK',
  DOCUMENT_WARNING = 'DOCUMENT_WARNING',
  RESCUE_RING = 'RESCUE_RING',
  MAIL = 'MAIL',
  ARROW_DOWN = 'ARROW_DOWN',
  LOCK = 'LOCK',
  WORLD = 'WORLD',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_NEXT_THIN = 'ARROW_NEXT_THIN',
  SEARCH = 'SEARCH',
  BROKER_PORTAL = 'BROKER_PORTAL',
  BROKER_PROMOTIONS = 'BROKER_PROMOTIONS',
  CLIENT_PORTAL = 'CLIENT_PORTAL',
  CLIENT_RESOURCES = 'CLIENT_RESOURCES',
  COVID19_SUPPORT = 'COVID19_SUPPORT',
  ELITE_LOYALTY = 'ELITE_LOYALTY',
  ENCOMPASS = 'ENCOMPASS',
  FINANCIAL_LITERACY = 'FINANCIAL_LITERACY',
  FINANCIAL_TOOLS_RESOURCES = 'FINANCIAL_TOOLS_RESOURCES',
  MORTGAGE_SERVICING_DOCS = 'MORTGAGE_SERVICING_DOCS',
  NEXTJOB = 'NEXTJOB',
  PREPAYMENT_CALCULATOR = 'PREPAYMENT_CALCULATOR',
  VIDEO_LIBRARY = 'VIDEO_LIBRARY',
  MEET_THE_TEAM = 'MEET_THE_TEAM',
  LENDING_AREAS = 'LENDING_AREAS',
  CDIC = 'CDIC',
  SADC = 'SADC',
  TARGET = 'TARGET',
  CHART = 'CHART',
  LIGHT_BULB = 'LIGHT_BULB',
  CERTIFICATE = 'CERTIFICATE',
  HEAD_GEAR = 'HEAD_GEAR',
  HOUSE = 'HOUSE',
  SETTINGS = 'SETTINGS',
  CALENDAR = 'CALENDAR',
  SAVE = 'SAVE',
}

interface OwnProps {
  className?: string;
  type: IconTypes;
}

type Props = OwnProps;

const Icons = {
  [IconTypes.ARROW_NEXT]: ArrowNext,
  [IconTypes.TEAM]: Team,
  [IconTypes.LOCATION_PIN]: LocationPin,
  [IconTypes.EMAIL_AT_SIGN]: EmailAtSign,
  [IconTypes.PHONE]: Phone,
  [IconTypes.SMART_PHONE]: Smartphone,
  [IconTypes.GROUP]: Group,
  [IconTypes.HOUSE_IN_HAND]: HouseInHand,
  [IconTypes.BAR_CHART]: BarChart,
  [IconTypes.ALERT_CIRCLE]: AlertCircle,
  [IconTypes.DOWNLOAD]: Download,
  [IconTypes.DOLLAR]: Dollar,
  [IconTypes.SEND_MAIL]: SendMail,
  [IconTypes.CHECK_MARK]: Checkmark,
  [IconTypes.DOCUMENT_WARNING]: DocumentWarning,
  [IconTypes.RESCUE_RING]: RescueRing,
  [IconTypes.MAIL]: Mail,
  [IconTypes.ARROW_DOWN]: ArrowDown,
  [IconTypes.LOCK]: Lock,
  [IconTypes.WORLD]: World,
  [IconTypes.ARROW_LEFT]: ArrowLeft,
  [IconTypes.ARROW_NEXT_THIN]: ArrowNextThin,
  [IconTypes.SEARCH]: Search,
  [IconTypes.BROKER_PORTAL]: BrokerPortal,
  [IconTypes.BROKER_PROMOTIONS]: BrokerPromotions,
  [IconTypes.CLIENT_PORTAL]: ClientPortal,
  [IconTypes.CLIENT_RESOURCES]: ClientResources,
  [IconTypes.COVID19_SUPPORT]: Covid19Support,
  [IconTypes.ELITE_LOYALTY]: EliteLoyalty,
  [IconTypes.ENCOMPASS]: Encompass,
  [IconTypes.FINANCIAL_LITERACY]: FinancialLiteracy,
  [IconTypes.FINANCIAL_TOOLS_RESOURCES]: FinancialToolsResources,
  [IconTypes.MORTGAGE_SERVICING_DOCS]: MortgageServicingDocs,
  [IconTypes.NEXTJOB]: NextJob,
  [IconTypes.PREPAYMENT_CALCULATOR]: PrepaymentCalculator,
  [IconTypes.VIDEO_LIBRARY]: VideoLibrary,
  [IconTypes.MEET_THE_TEAM]: MeetTheTeam,
  [IconTypes.LENDING_AREAS]: LendingAreas,
  [IconTypes.CDIC]: CDIC_ICON,
  [IconTypes.SADC]: SADC_ICON,
  [IconTypes.TARGET]: Target,
  [IconTypes.CERTIFICATE]: Certificate,
  [IconTypes.LIGHT_BULB]: LightBulb,
  [IconTypes.HEAD_GEAR]: HeadGear,
  [IconTypes.CHART]: Chart,
  [IconTypes.HOUSE]: House,
  [IconTypes.SETTINGS]: Settings,
  [IconTypes.CALENDAR]: Calendar,
  [IconTypes.SAVE]: Save,
};

const Icon: React.FC<Props> = ({ className = '', type }) => {
  const FoundIcon = Icons[type];
  return <FoundIcon className={`Icon ${className}`} />;
};

export default Icon;
