import { NestedLink } from '../../components/MenuNavigationLink/MenuNavigationLink';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const links: NestedLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.CONTACT_US),
    textKey: 'pageTitles.contactUs',
    isPartiallyActive: true,
    nestedLinks: [
      {
        to: getPathFromRouteKey(RouteKeys.CONTACT_US.CUSTOMER_SERVICE_SUPPORT),
        textKey: 'pageTitles.customerServiceSupport',
      },
      {
        to: getPathFromRouteKey(RouteKeys.CONTACT_US.COMPLAINTS_HANDLING),
        textKey: 'pageTitles.complaintHandling',
      },
    ],
  },
];

export default links;
