import React, { FormEvent, HTMLProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Icon, { IconTypes } from '../../Icon/Icon';
import './style.scss';

// Add supported inputs ONLY. Verify that input works well with existing types before adding a new one
export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export interface InputOptions {
  type: InputType;
  label: ReactNode;
  placeHolder?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
  groupOptions?: {
    leftSide?: {
      icon?: IconTypes;
      text?: string;
    };
    rightSide?: {
      icon?: IconTypes;
      text?: string;
    };
  };
}

interface OwnProps {
  id: string;
  className?: string;
  options: InputOptions;
  isInvalid?: boolean;
  validationMessage?: string;
  onChange?: (...args: any[]) => any;
  required?: boolean;
}

type Props = OwnProps;

const Input: React.FC<Props> = ({
  className = '',
  id,
  options,
  onChange = () => {},
  isInvalid,
  validationMessage = '',
  required,
}) => {
  const { t } = useTranslation();

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value, id);
  };

  const getGroupedContent = (opts: {
    icon?: IconTypes;
    text?: string;
    className?: string;
    type?: string;
  }) => {
    return (
      <div className={`${opts.className}`}>
        {opts.icon && <Icon type={opts.icon} />}
        {opts.text && <span id={`${id}-group-option-${opts.type || ''}`}>{opts.text}</span>}
      </div>
    );
  };

  const leftGroupOption =
    options.groupOptions && options.groupOptions.leftSide
      ? getGroupedContent({
          className: 'Input__input-group-prepend',
          ...options.groupOptions.leftSide,
          type: 'left',
        })
      : null;

  const rightGroupOption =
    options.groupOptions && options.groupOptions.rightSide
      ? getGroupedContent({
          className: 'Input__input-group-append',
          ...options.groupOptions.rightSide,
          type: 'right',
        })
      : null;

  const errorMessage =
    !!isInvalid && validationMessage ? (
      <ErrorMessage id={`error-${id}`}>{validationMessage}</ErrorMessage>
    ) : null;

  const inputClassNames = classNames('Input', className, {
    'Input__is-invalid': !!isInvalid,
  });

  const inputAria: { [key: string]: string } = {};

  if (errorMessage) {
    inputAria['aria-describedby'] = `error-${id}`;
  }

  if (options.groupOptions?.leftSide?.text) {
    if (inputAria['aria-describedby']) {
      inputAria['aria-describedby'] += ` ${id}-group-option-left`;
    } else {
      inputAria['aria-describedby'] = `${id}-group-option-left`;
    }
  }

  if (options.groupOptions?.rightSide?.text) {
    if (inputAria['aria-describedby']) {
      inputAria['aria-describedby'] += ` ${id}-group-option-right`;
    } else {
      inputAria['aria-describedby'] = `${id}-group-option-right`;
    }
  }

  const requiredLabel = t(`${LocaleNameSpaceKeys.COMMON}:requiredField`);

  const label = required ? (
    <>
      {options.label} {requiredLabel}
    </>
  ) : (
    options.label
  );

  switch (options.type) {
    case InputType.CHECKBOX:
      return (
        <div className={inputClassNames}>
          <div className="Input__group-wrapper">
            {leftGroupOption}
            <input
              type={options.type}
              id={id}
              onChange={handleOnChange}
              {...options.inputProps}
              {...inputAria}
            />
            <label htmlFor={id}>{label}</label>
            {rightGroupOption}
          </div>
          {errorMessage}
        </div>
      );
    default:
      return (
        <div className={inputClassNames}>
          <label htmlFor={id}>{label}</label>
          <div className="Input__group-wrapper">
            {leftGroupOption}
            <input
              type={options.type}
              id={id}
              onChange={handleOnChange}
              {...options.inputProps}
              {...inputAria}
            />
            {rightGroupOption}
          </div>
          {errorMessage}
        </div>
      );
  }
};

export default Input;
