/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import { CookiesProvider } from 'react-cookie';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SEO, { SeoOptions } from '../seo';
import HeroImage from '../HeroImage/HeroImage';
import CookieConsent from '../CookieConsent/CookieConsent';
import './style.scss';

export enum HeroSectionColors {
  SKY_BLUE = 'sky-blue',
  LIGHT_GREEN = 'light-green',
  PARROT_GREEN = 'parrot-green',
  FOREST_GREEN = 'forest-green',
  SLATE_INDIGO = 'slate-indigo',
  MANGO_YELLOW = 'mango-yellow',
  CHERRY_RED = 'cherry-red',
}

export interface HeroSectionOptions {
  heroImage: {
    fileName: string;
    alt: string;
  };
  accentColor: HeroSectionColors /** Used on strip and digi blocks */;
  blockText?: string;
  shouldBeDigiBlock?: boolean;
}

export interface LayoutOptions {
  seo: SeoOptions;
  shouldEnableMaxWidth?: boolean;
  page?: {
    title?: string;
  };
  heroSection?: {
    custom?: React.ReactNode /** Custom will override all the other options */;
    options?: HeroSectionOptions;
  };
}
interface OwnProps {
  children: React.ReactNode;
  className?: string;
  options: LayoutOptions;
}

type Props = OwnProps;

const Layout: React.FC<Props> = ({ children, className = '', options }) => {
  const [cookieConsentInert, setCookieConsentInert] = useState(false);
  const [mobileNavigationInert, setMobileNavigationInert] = useState(false);

  const getHeroImageTextBlock = (opts: {
    blockText?: string;
    shouldBeDigiBlock: boolean;
    accentColor?: HeroSectionColors;
  }): React.ReactNode => {
    if (opts.blockText) {
      if (opts.shouldBeDigiBlock) {
        return (
          <div
            className={`Layout__hero-text-block Layout__hero-text-block--with-digi-block ${
              opts.accentColor || ''
            }`}
          >
            <p className="h1">{opts.blockText}</p>
          </div>
        );
      }

      return (
        <div
          className={`Layout__hero-text-block Layout__hero-text-block--without-digi-block ${
            opts.accentColor || ''
          }`}
        >
          <p className="h1">{opts.blockText}</p>
        </div>
      );
    }
    return React.Fragment;
  };
  const getPreDefinedHeroSection = (heroSectionOptions: HeroSectionOptions) => {
    return (
      <div className={`Layout__hero-section ${heroSectionOptions.accentColor || ''}`}>
        <HeroImage {...heroSectionOptions.heroImage} className="Layout__hero-section-image" />
        <div className="hero-section-text-wrapper">
          {getHeroImageTextBlock({
            blockText: heroSectionOptions.blockText,
            shouldBeDigiBlock: !!heroSectionOptions.shouldBeDigiBlock,
            accentColor: heroSectionOptions.accentColor,
          })}
        </div>
      </div>
    );
  };

  const getHeroSection = () => {
    if (options && options.heroSection) {
      if (options.heroSection.custom) {
        return <>{options.heroSection.custom}</>;
      }
      if (options.heroSection.options) {
        return getPreDefinedHeroSection(options.heroSection.options);
      }
    }
    return React.Fragment;
  };

  const getTitle = () => {
    if (options && options.page) {
      if (options.page.title) {
        return <h1 className="Layout__page-title">{options.page.title}</h1>;
      }
    }
    return React.Fragment;
  };

  const dynamicClassNames = classNames('Layout', className, {
    'Layout__enable-max-width':
      options.shouldEnableMaxWidth === undefined ? true : options.shouldEnableMaxWidth,
  });

  return (
    <>
      <SEO {...options.seo} />
      <Header
        cookieConsentInert={cookieConsentInert}
        mobileNavigationInert={mobileNavigationInert}
        setMobileNavigationInert={setMobileNavigationInert}
      />
      <div
        className={dynamicClassNames}
        {...(cookieConsentInert || mobileNavigationInert ? { inert: 'true' } : {})}
      >
        {getHeroSection()}
        <main
          id="main-content"
          className="Layout__main-container"
          {...(cookieConsentInert ? { inert: 'true' } : {})}
        >
          {getTitle()}
          <>{children}</>
        </main>
        <Footer inert={cookieConsentInert} />
        <CookiesProvider />
        {/* <CookieConsent
          location="bottom"
          buttonText={t('cookiesNoteBlock.buttonLabel')}
          cookieName="HTB_COOKIE_CONSENT"
          style={{ background: '#333333' }}
          buttonStyle={{ background: '#006f44', color: '#ffffff', fontSize: '14px' }}
          expires={150}
          sameSite="none"
          cookieSecurity
        >
          <Trans i18nKey="cookiesNoteBlock.note">
            <Link to={getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)} />
          </Trans>
        </CookieConsent> */}
      </div>
      <CookieConsent setInert={setCookieConsentInert} />
    </>
  );
};

export default Layout;
