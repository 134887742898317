import { LocaleTag } from '../../../config/locales';

/**
 * This function's job is to return the English path of a route
 *
 * Examples -
 * getPathFromRouteKeys(RouteKeys.HOME.INDEX) will return `/`
 * getPathFromRouteKeys(RouteKeys.HOME.INDEX.path) will return `/`
 * getPathFromRouteKeys(RouteKeys.HOME) will return `/`
 *
 * @param routeKey is the object from /config/RouteKeys.ts
 */
export const getPathFromRouteKey = (routeKey: any): string => {
  if (!routeKey) return '';

  if (!Object.keys(routeKey)) {
    if (process.env.NODE_ENV === 'development') {
      console.error(`You supplied an empty object to retrieve the path from RouteKeys`);
    }
    return '';
  }

  if (typeof routeKey === 'string') {
    return routeKey;
  }

  // User supplied ROUTEKEYS.PAGENAME
  if (routeKey.INDEX) {
    return routeKey.INDEX.path[LocaleTag.EN];
  }

  // User supplied ROUTEKEYS.PAGENAME.INDEX
  if (!routeKey.INDEX && routeKey.path) {
    return routeKey.path[LocaleTag.EN];
  }

  // User supplied ROUTEKEYS.PAGENAME.INDEX.path
  if (!routeKey.path && routeKey[LocaleTag.EN]) {
    return routeKey[LocaleTag.EN];
  }

  if (process.env.NODE_ENV === 'development') {
    // Could be that user just supplied the whole RouteKeys object
    console.error(
      `Something is wrong with the RouteKey received. Please verify that the structure of Object matches the keys in '/config/RouteKeys.ts'`
    );
  }

  return '';
};
