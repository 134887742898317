import React from 'react';
import { LinkGetProps, LinkProps } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';

import { useLocaleContext } from '../../context/Locale';
import { generateLocalizedPath } from '../../shared/utils/localizedPath';
import mappedRoutes from '../../shared/mappedRoutes';

interface OwnProps {
  partiallyActive?: boolean;
  activeClassName?: string;
}

type Props = OwnProps & Omit<LinkProps<any>, 'ref'>;
const Link: React.FC<Props> = ({
  to,
  children,
  partiallyActive = false,
  activeClassName = '',
  className = '',
  ...rest
}) => {
  const { activeLocaleTag } = useLocaleContext();

  const findPath = (): string => {
    const foundMappedRoute = mappedRoutes.get(to);

    if (foundMappedRoute) {
      const foundPath = encodeURI(foundMappedRoute.path[activeLocaleTag]);

      if (foundPath) {
        // if the path is found, redirect the user to the path
        const pathWithExistingQuery = `${foundPath}`;
        return generateLocalizedPath(activeLocaleTag, pathWithExistingQuery);
      }
    }

    return generateLocalizedPath(activeLocaleTag, '');
  };

  const handleGetProps = (props: LinkGetProps) => {
    let parsedPath = findPath();
    let isOnCurrentPage = to === props.location.pathname || props.location.pathname === parsedPath;

    if (partiallyActive) {
      parsedPath =
        parsedPath.charAt(parsedPath.length - 1) === '/'
          ? parsedPath.substring(parsedPath.length - 1, 0)
          : parsedPath;
      isOnCurrentPage = props.location.pathname.includes(parsedPath);
    }

    return {
      className: `Link ${className} ${isOnCurrentPage && activeClassName}`,
    };
  };

  return (
    <GatsbyLink getProps={handleGetProps} to={`${findPath()}`} {...rest}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
