import { LocalStorageKeys } from '../keys/LocalStorageKeys';
import isWindowUndefined from '../shared/utils/isWindowUndefined';

export interface User {
  username: string;
  loggedOnDate: Date;
}

export const getUser = (): User | null => {
  if (!isWindowUndefined()) {
    const rawLoggedInUser = window.localStorage.getItem(LocalStorageKeys.LOGGED_IN_USER);
    if (rawLoggedInUser) {
      return JSON.parse(rawLoggedInUser) as User;
    }
  }

  return null;
};

const setUser = (user: User | unknown) => {
  if (!isWindowUndefined()) {
    window.localStorage.setItem(LocalStorageKeys.LOGGED_IN_USER, JSON.stringify(user));
  }
};

export const handleLogin = (userInfo: { username: string; password: string }): Promise<boolean> => {
  const usernames = process.env.GATSBY_BROKER_PORTAL_USERNAME?.split(',');
  if (!usernames) return Promise.resolve(false);

  if (
    usernames.includes(userInfo.username) &&
    userInfo.password === process.env.GATSBY_BROKER_PORTAL_PASSWORD
  ) {
    setUser({
      username: userInfo.username,
      loggedOnDate: new Date(),
    });
    return Promise.resolve(true);
  }

  return Promise.resolve(false);
};

export const isLoggedIn = () => {
  const user = getUser();
  if (user) {
    const usernames = process.env.GATSBY_BROKER_PORTAL_USERNAME?.split(',');
    if (!usernames) return false;

    if (usernames.includes(user.username)) {
      return true;
    }
  }
  return false;
};

export const logout = (): Promise<boolean> => {
  setUser({});
  return Promise.resolve(true);
};
