import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';

export enum FooterLinkType {
  External = 'external',
  Internal = 'internal',
}

export interface FooterLink {
  to: string;
  textKey: string;
  type?: FooterLinkType;
}

export interface FooterLinkBlock {
  headingKey: string;
  links: FooterLink[];
}

const service: FooterLinkBlock = {
  headingKey: 'footerTitles.services',
  links: [
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE),
      textKey: 'pageTitles.customerCentre',
    },
    {
      to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE),
      textKey: 'pageTitles.brokerCentre',
    },
    {
      to: getPathFromRouteKey(RouteKeys.DEPOSITS),
      textKey: 'pageTitles.deposits',
    },
  ],
};

const resources: FooterLinkBlock = {
  headingKey: 'footerTitles.resources',
  links: [
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR),
      textKey: 'pageTitles.prepaymentCalculator',
    },
  ],
};

const company: FooterLinkBlock = {
  headingKey: 'footerTitles.company',
  links: [
    {
      to: getPathFromRouteKey(RouteKeys.ABOUT_US),
      textKey: 'pageTitles.aboutUs',
    },
    {
      to: getPathFromRouteKey(RouteKeys.ABOUT_US.COMMUNITY_CAREGIVING),
      textKey: 'pageTitles.communityCaregiving',
    },
    {
      to: getPathFromRouteKey(RouteKeys.ABOUT_US.FRAUD_ALERT),
      textKey: 'pageTitles.fraudAlert',
    },
    {
      to: getPathFromRouteKey(RouteKeys.ABOUT_US.SCC),
      textKey: 'pageTitles.scc',
    },
  ],
};

const contact: FooterLinkBlock = {
  headingKey: 'footerTitles.contact',
  links: [
    {
      to: getPathFromRouteKey(RouteKeys.CONTACT_US.CUSTOMER_SERVICE_SUPPORT),
      textKey: 'pageTitles.customerServiceSupport',
    },
    {
      to: getPathFromRouteKey(RouteKeys.CONTACT_US.COMPLAINTS_HANDLING),
      textKey: 'pageTitles.complaintHandling',
    },
  ],
};

export const footerDocsLinks: FooterLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.ELECTRONIC_ACCESS_AGREEMENT),
    textKey: 'footerTitles.electronicAccessAgreement',
  },
  {
    to: getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT),
    textKey: 'footerTitles.privacyStatement',
  },
  {
    to: getPathFromRouteKey(RouteKeys.TERMS_CONDITION),
    textKey: 'footerTitles.termsOfUse',
  },
  {
    to: getPathFromRouteKey(RouteKeys.REGULATORY_INFORMATION),
    textKey: 'footerTitles.regulatoryInformation',
  },
  {
    to: getPathFromRouteKey(RouteKeys.ACCESSIBILITY),
    textKey: 'footerTitles.accessibility',
    type: FooterLinkType.External,
  },
  {
    to: 'onClick-launchCookieConsent',
    textKey: 'footerTitles.cookieSettings',
  },
];

export default [service, resources, company, contact];
