import React, { memo, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';
// import { navigate } from '@reach/router';
import { Fade as Hamburger } from 'hamburger-react';

import LanguagePicker from '../LanguagePicker/LanguagePicker';
import Link from '../Link/Link';
import Logo from '../../assets/htb_logo.svg';
import FRLogo from '../../assets/fr-htb_logo.svg';
import NavigationBar from '../NavigationBar/NavigationBar';
import navigationData from '../../data/navigation';
import { isLoggedIn, logout } from '../../services/auth';
import Button from '../primitives/Button/Button';
import { Select } from '../primitives';
import mappedRoutes from '../../shared/mappedRoutes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { useLocaleContext } from '../../context/Locale';
import isWindowUndefined from '../../shared/utils/isWindowUndefined';
import MobileMenu from '../MobileMenu/MobileMenu';
import { LocaleTag } from '../../../config/locales';
import CommonData from '../../data/common/common';
import './style.scss';
import { LocaleNameSpaceKeys } from '../../localization/translations';

interface OwnProps {
  className?: string;
  cookieConsentInert?: boolean;
  mobileNavigationInert?: boolean;
  setMobileNavigationInert: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OptionType {
  label: string;
  value: string;
}

type Props = OwnProps;

const loginRoute = mappedRoutes.get(
  getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL.LOGIN)
);

if (!loginRoute) {
  if (process.env.NODE_ENV === 'development') {
    throw new Error('Cannot find Login route, please check Header component');
  }
}

const Header: React.FC<Props> = ({
  className,
  cookieConsentInert,
  mobileNavigationInert,
  setMobileNavigationInert,
}: Props) => {
  const { t } = useTranslation();
  const { activeLocaleTag } = useLocaleContext();

  const [isLoggedInState, setIsLoggedInState] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useLayoutEffect(() => {
    setIsLoggedInState(isLoggedIn());
  });

  const handleLogout = () => {
    logout();
    if (!isWindowUndefined()) {
      window.location.reload();
    }
  };

  const handleChange = (args: ValueType<OptionType>) => {
    if (args && !isWindowUndefined()) {
      window.open((args as OptionType).value);
    }
  };

  const options: OptionType[] = [
    {
      label: t('customer'),
      value: process.env.GATSBY_CUSTOMER_PORTAL_URL || '/',
    },
    {
      label: t('broker'),
      value: CommonData.websites.brokerPortal[activeLocaleTag],
    },
  ];

  const getLoginHeaderDropDown = () => {
    if (!isLoggedInState) {
      return (
        <div className="Header__login-dropdown">
          <Select
            options={options}
            placeholder={t('login')}
            value={null}
            onChange={handleChange}
            isSearchable={false}
            aria-label={t(`${LocaleNameSpaceKeys.COMMON}:ariaLabels.authPortal`)}
          />
        </div>
      );
    }
    return React.Fragment;
  };

  const onToggle = () => {
    if (isOpen) {
      setOpen(false);
      setMobileNavigationInert(false);
    } else {
      setOpen(true);
      setMobileNavigationInert(true);
    }
  };

  const getHamburger = () => {
    return (
      <Hamburger
        toggled={isOpen}
        toggle={onToggle}
        color="#006f44"
        hideOutline={false}
        label={t(`${LocaleNameSpaceKeys.COMMON}:ariaLabels.mobileMenu`)}
      />
    );
  };

  return (
    <header className={`Header ${className}`} {...(cookieConsentInert ? { inert: 'true' } : {})}>
      <div className="Header__container">
        <div className="Header__level-one">
          <Link to="/" className="Header__logo-link" aria-label={t('haventreeBank')}>
            {activeLocaleTag === LocaleTag.EN ? <Logo /> : <FRLogo />}
          </Link>
          <a className="skip-to-main" href="#main-content">
            {t('skipToMain')}
          </a>
          <div className="Header__second-half">
            <LanguagePicker />
            {getLoginHeaderDropDown()}

            {isLoggedInState && (
              <Button id="logout" onClick={handleLogout}>
                {t('logout')}
              </Button>
            )}

            {getHamburger()}
          </div>
        </div>

        <NavigationBar navigationLinks={navigationData} />
      </div>
      <MobileMenu navigationLinks={navigationData} isOpen={isOpen} toggle={setOpen}>
        <LanguagePicker />

        {getLoginHeaderDropDown()}

        {isLoggedInState && (
          <Button id="logout" onClick={handleLogout}>
            {t('logout')}
          </Button>
        )}
      </MobileMenu>
    </header>
  );
};

Header.defaultProps = {
  className: '',
  mobileNavigationInert: false,
  cookieConsentInert: false,
};

export default memo(Header);
