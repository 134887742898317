import React from 'react';

export interface OwnProps {
  shouldOpenInNewTab?: boolean;
}

type Props = OwnProps & React.HTMLProps<HTMLAnchorElement>;

const ExternalLink: React.FC<Props> = ({
  href,
  shouldOpenInNewTab = true,
  className = '',
  children,
  ...rest
}) => {
  const modifiedProps = {
    target: shouldOpenInNewTab ? '__blank' : '_self',
    rel: 'noopener',
  };

  return (
    <a href={href} className={`ExternalLink ${className}`} {...rest} {...modifiedProps}>
      {children}
    </a>
  );
};

export default ExternalLink;
