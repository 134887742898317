import { LocaleTag } from '../../../config/locales';

/**
 *
 * @param path is a path to a page in the app
 * @param locale is a short language code, example "en" or "fr"
 */
export const generateLocalizedPath = (locale: LocaleTag, path: string) => {
  return `/${locale.toLowerCase()}${path}`;
};
