import { NestedLink } from '../../components/MenuNavigationLink/MenuNavigationLink';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const links: NestedLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.ABOUT_US),
    textKey: 'pageTitles.aboutUs',
    isPartiallyActive: true,
    nestedLinks: [
      {
        to: getPathFromRouteKey(RouteKeys.ABOUT_US.COMMUNITY_CAREGIVING),
        textKey: 'pageTitles.communityCaregiving',
      },
      {
        to: getPathFromRouteKey(RouteKeys.ABOUT_US.FRAUD_ALERT),
        textKey: 'pageTitles.fraudAlert',
      },
      {
        to: getPathFromRouteKey(RouteKeys.ABOUT_US.SCC),
        textKey: 'pageTitles.scc',
      },
    ],
  },
];

export default links;
