import React, { MouseEventHandler, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../Link/Link';
import './style.scss';
import { NestedLink } from '../MenuNavigationLink/MenuNavigationLink';
import Icon, { IconTypes } from '../Icon/Icon';

export interface OwnProps extends NestedLink {
  className?: string;
  onClick: (any: NestedLink) => any;
  toggleMenuOnTap: (value: boolean) => any;
}

type Props = OwnProps;

const MobileNavigationLink: React.FC<Props> = ({
  to,
  textKey,
  nestedLinks,
  className = '',
  isPartiallyActive,
  onClick,
  toggleMenuOnTap,
}: Props) => {
  const { t } = useTranslation();

  const getIsPartiallyActive = () => {
    if (to !== '/') {
      return typeof isPartiallyActive !== 'undefined'
        ? isPartiallyActive
        : nestedLinks && nestedLinks.length > 1;
    }

    return false;
  };

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (nestedLinks && nestedLinks.length > 0) {
      e.preventDefault();
      onClick({ to, textKey, nestedLinks, isPartiallyActive });
    } else {
      toggleMenuOnTap(false);
    }
  };

  return (
    <li className={`MobileNavigationLink ${className}`}>
      <Link
        to={to}
        className={`MobileNavigationLink__internal-link ${className}`}
        activeClassName={`MobileNavigationLink__internal-link--active ${className}`}
        partiallyActive={getIsPartiallyActive()}
        onClick={handleOnClick}
      >
        {t(textKey)}
        {nestedLinks && nestedLinks.length && <Icon type={IconTypes.ARROW_NEXT_THIN} />}
      </Link>
    </li>
  );
};

export default memo(MobileNavigationLink);
