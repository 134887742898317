import { NestedLink } from '../../components/MenuNavigationLink/MenuNavigationLink';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';

const links: NestedLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE),
    textKey: 'pageTitles.brokerCentre',
    isPartiallyActive: true,
    nestedLinks: [
      {
        to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL),
        textKey: 'pageTitles.brokerPortal',
        isPartiallyActive: false,
      },
      {
        to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM),
        textKey: 'pageTitles.meetTheTeam',
        isPartiallyActive: false,
      },
      {
        to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.VIDEO_LIBRARY),
        textKey: 'pageTitles.videoLibrary',
        isPartiallyActive: false,
      },
    ],
  },
];

// if (isLoggedIn()) {
// TODO Move this to redux
// @ts-ignore
// }

// links[0].nestedLinks[0].nestedLinks = [...brokerPortalNestedLinks];
export default links;
