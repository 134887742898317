import React, { memo } from 'react';
import './style.scss';
import Icon, { IconTypes } from '../Icon/Icon';

interface OwnProps {
  className?: string;
  message?: string; // if both are provided, it will give precedence to message
  children?: string;
  id?: string;
}

type Props = OwnProps;

const ErrorMessage: React.FC<Props> = ({ message = '', children, className = '', id }) => {
  if (!message && !children) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `ErrorMessage: You need to provide at least one of 'message' or 'children' prop for the error to be displayed`
      );
    }
  }
  return (
    <div className={`ErrorMessage ${className}`}>
      <Icon type={IconTypes.ALERT_CIRCLE} />
      <p id={id}>{message || children}</p>
    </div>
  );
};

export default memo(ErrorMessage);
